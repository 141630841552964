body {
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    background-color: #000;
    overflow: hidden;
  }
  
  h2 {
    font-size: 4em;
    text-align: center;
  }
  
  p {
    font-size: 1.5em;
    text-align: justify;
    padding: 50px;
    
  }
  
  .cursor {
    height: 200px;
    width: 200px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    z-index: -1;
  }